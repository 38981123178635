<template>
  <div class="zt-page-content">
    <div class="page-header">物品类别管理</div>
    <div class="zt-block tab">
      <el-form ref="search" :inline="true" :label-width="'120px'">
        <el-row style="padding-top: 15px">
          <el-form-item label="物品类别编号：" size="mini">
            <el-input
              v-model="search.goodsTypeId"
              clearable
              size="mini"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="物品类别名称：" size="mini">
            <el-input
              v-model="search.goodsType"
              clearable
              size="mini"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row type="flex" justify="end">
          <el-form-item label size="mini">
            <el-button
              type="primary"
              size="mini"
              @click="onSearch"
              :loading="loading"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-button size="mini" @click="onReset" icon="el-icon-refresh-left"
              >重置</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div class="zt-block" style="height: calc(100% - 175px)">
      <el-col
        :span="5"
        style="background-color: white; padding: 10px; height: 100%"
      >
        <div
          class=""
          style="
            min-height: 48px;
            border-bottom: 1px solid #eee;
            margin-bottom: 10px;
          "
        >
          <div class="" style="padding: 10px 0; font-size: 16px; float: left">
            物品类别
          </div>
          <el-button
            size="mini"
            type="primary"
            @click="create"
            style="margin-top: 5px; margin-left: 10px; float: right"
          >
            <i class="icon iconfont icon-tianjia" style="margin-right: 5px"></i>
            新增类目
          </el-button>
        </div>
        <div
          style="
            margin-top: 10px;
            height: calc(100% - 80px);
            margin-bottom: 10px;
            overflow-y: auto;
          "
        >
          <el-tree
            v-loading="treeLoading"
            ref="treeView"
            :data="treeData"
            :props="treeProps"
            :indent="25"
            node-key="id"
            accordion
            :current-node-key="currentNodekey"
            @node-contextmenu="nodeClick"
            @current-change="currentChange"
            :expand-on-click-node="false"
            highlight-current
          >
            <span
              @dblclick="node.expanded = !node.expanded"
              class="custom-tree-node"
              style="width: 100%"
              slot-scope="{ node, data }"
            >
              <i
                v-if="data.isDeleted == 1"
                style="font-size: 16px; margin: 0 3px 0 4px"
                class="el-icon-folder-checked"
              ></i>
              <i
                v-else
                style="font-size: 16px; margin: 0 3px 0 4px"
                class="el-icon-folder-opened"
              ></i>
              <span style="margin-left: 5px; user-select: none">{{
                node.label
              }}</span>
            </span>
          </el-tree>
        </div>
      </el-col>
      <el-col :span="19" style="height: 100%; padding-left: 30px">
        <div style="line-height: 40px; padding: 10px">
          <el-button
            size="mini"
            type="success"
            @click="checkCode"
            :disabled="selectList.length == 0"
          >
            <i class="el-icon-edit" style="margin-right: 5px"></i>
            审核
          </el-button>
          <el-button
            size="mini"
            type="default"
            @click="nCheckCode"
            :disabled="selectList.length == 0"
          >
            <i class="el-icon-edit" style="margin-right: 5px"></i>
            反审核
          </el-button>
        </div>

        <div class="list" style="height: calc(100% - 60px); overflow-y: auto">
          <el-table
            v-loading="loading"
            :data="list"
            style="width: 100%"
            @select-all="onSelectRowAll"
            @select="onSelectRow"
            :empty-text="'无数据'"
          >
            <el-table-column
              align="center"
              width="60"
              type="selection"
            ></el-table-column>
            <el-table-column prop="goodsTypeId" label="物品类别编号">
              <template slot-scope="scope">
                <div>{{ scope.row.goodsTypeId }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="goodsType" label="物品类别名称">
              <template slot-scope="scope">
                <div>{{ scope.row.goodsType }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="isDeleted" label="物品状态">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.isDeleted | filter(isDeletedList) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="meter_number" label="操作">
              <template slot-scope="scope">
                <el-button
                  :disabled="scope.row.isDeleted == 1"
                  type="text"
                  @click="edit(scope.row)"
                  >修改</el-button
                >
                <el-button
                  v-if="scope.row.isDeleted == 0"
                  style="color: red; padding: 0px; line-height: 0px"
                  @click="del(scope.row)"
                  type="text"
                  >删除</el-button
                >
                <el-button
                  v-else
                  type="text"
                  :disabled="scope.row.isDeleted == 1"
                  @click="del(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="padding: 12px">
          <el-pagination
            background
            @current-change="onPageChange"
            @size-change="onPageSizeChange"
            :current-page="search.pageIndex"
            :page-size="search.pageSize"
            layout="prev, pager, next"
            :total="search.total"
          ></el-pagination>
        </div>
      </el-col>
      <el-dialog
        class="form"
        :fullscreen="false"
        :title="form.formTitle"
        :custom-class="'form-dialog'"
        width="550px"
        :center="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :modal-append-to-body="true"
        :visible.sync="form.formShow"
        @close="formClose"
      >
        <el-form
          ref="form"
          :model="form.baseOut"
          :inline="true"
          label-width="180px"
          :rules="addFormRules"
        >
          <el-form-item label="物品类别编号：" prop="goodsTypeId" size="mini">
            <el-input
              v-model="form.baseOut.goodsTypeId"
              clearable
              size="mini"
              placeholder="请输入"
              :maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="物品类别名称：" prop="goodsType" size="mini">
            <el-input
              v-model="form.baseOut.goodsType"
              clearable
              size="mini"
              placeholder="请输入"
              :maxlength="50"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button size="mini" @click="form.formShow = false">取消</el-button>
          <el-button size="mini" type="primary" @click="onSave">保存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { WLBASE_URL } from "@/config";
export default {
  components: {},
  filters: {
    filter(val, isDeletedList) {
      return isDeletedList.filter((item) => val == item.value)[0].label;
    },
  },
  data() {
    return {
      isDeletedList: [
        { label: "未审核", value: 0 },
        { label: "已审核", value: 1 },
      ],
      loading: false,
      selectList: [],
      search: {
        goodsTypeId: "", //运单编号
        goodsType: "",
        orgId: undefined,
        id: "",
        tenantId: 0,
        pageIndex: 1,
        pageSize: 10,
        total: 0, //总数
      },
      form: {
        formTitle: "",
        formShow: false,
        baseOut: {
          preId: "",
          id: "",
          goodsTypeId: "",
          goodsType: "",
          isDeleted: 0,
          tenantId: 0,
        },
      },
      // 表单校验
      addFormRules: {
        goodsTypeId: [
          // { required: true },
          { required: true, message: "请输入物品编号", trigger: "blur" },
        ],
        goodsType: [
          { required: true, message: "请输入物品名称", trigger: "blur" },
        ],
      },
      total: 0,
      list: [],
      treeData: [],
      treeProps: {
        label: "name",
        children: "child",
      },
      currentNodekey: "",
      treeLoading: false,
    };
  },
  async created() {
    this.getList();
    this.getTree();
  },
  methods: {
    formClose() {
      this.$refs.form.resetFields();
    },
    del(row) {
      let url = WLBASE_URL + "/api/PassTokenWaybill/DelGoodsTypeByIdDal";
      let data = {
        idList: [],
        orgId: this.search.orgId,
        tenantId: 0,
      };
      data.idList.push(row.id);
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios({
          url,
          method: "POST",
          data,
        }).then((res) => {
          if (res.data.success) {
            this.$message.success(res.data.content);
            this.getList();
            this.getTree();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      });
    },
    edit(data) {
      this.form.baseOut.id = data.id;
      this.form.formTitle = "修改物品类别";
      this.form.baseOut.goodsType = data.goodsType;
      this.form.baseOut.goodsTypeId = data.goodsTypeId;
      this.form.formShow = true;
    },
    create() {
      this.form.formShow = true;
      this.form.formTitle = "新增物品类别";
      this.form.baseOut.goodsTypeId = "";
      this.form.baseOut.goodsType = "";
      this.form.baseOut.id = "";
    },
    onSave() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let url = WLBASE_URL + "/api/PassTokenWaybill/AddGoodsTypeDal";
          this.form.baseOut.orgId = this.search.orgId;
          this.axios({
            url,
            method: "POST",
            data: this.form.baseOut,
          }).then((res) => {
            if (res.data.success) {
              this.$message.success(res.data.content);
              this.getList();
              this.getTree();
              this.form.formShow = false;
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    checkCode() {
      let status = this.selectList.every((item) => item.isDeleted == 0);
      if (status) {
        let url = WLBASE_URL + "/api/PassTokenWaybill/UpdataIsDeleteDal";
        let data = {
          idList: [],
        };
        data.idList = this.selectList.map((item) => item.id);
        this.$confirm("确认审核?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.axios({
            url,
            method: "POST",
            data,
          }).then((res) => {
            if (res.data.success) {
              this.$message.success("审核成功");
              this.getList();
              this.getTree();
            } else {
              this.$message.error("审核失败");
            }
          });
        });
      } else {
        this.$message.error("存在已审核的物品");
      }
    },
    nCheckCode() {
      let status = this.selectList.every((item) => item.isDeleted == 1);
      if (status) {
        let url = WLBASE_URL + "/api/PassTokenWaybill/UpdataIsDeleteDal";
        let data = {
          idList: [],
        };
        data.idList = this.selectList.map((item) => item.id);
        this.$confirm("确认反审核?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.axios({
            url,
            method: "POST",
            data,
          }).then((res) => {
            if (res.data.success) {
              this.$message.success("反审核成功");
              this.getList();
              this.getTree();
            } else {
              this.$message.error("反审核失败");
            }
          });
        });
      } else {
        this.$message.error("存在未审核的物品");
      }
    },
    nodeClick(event, data, node) {
      this.$refs["treeView"].setCurrentKey(node.key);
      this.currentChange(data, node);
    },
    currentChange(data, node) {
      this.search.id = data.id;
      this.form.baseOut.preId = data.id;
      this.getList(1);
    },
    transFormTreeData(arr) {
      if (arr != null) {
        return arr.map((n) => {
          return {
            name: n.goodsType,
            isDeleted: n.isDeleted,
            id: n.id,
            createdBy: n.createdBy,
            preId: n.preId,
            child: this.transFormTreeData(n.children),
          };
        });
      }
    },
    getTree() {
      this.treeLoading = true;
      let url = WLBASE_URL + "/api/PassTokenWaybill/GetGoodsTypeMenuTreeDal";
      let data = {
        orgId: this.search.orgId,
        tenantId: 0,
      };
      this.axios({
        url,
        method: "POST",
        data,
      }).then((res) => {
        if (res.data.success) {
          this.treeLoading = false;
          let treeData = [{ name: "全部", id: "" }];
          let tree = this.transFormTreeData(res.data.content);
          this.treeData = treeData.concat(tree);
          this.currentNodekey = this.treeData[0].id;
          console.log(res, "resssss");
        } else {
          this.$message.error("数据请求失败");
        }
      });
    },
    onSelectRow(e) {
      this.selectList = e;
    },
    onSelectRowAll(e) {
      this.selectList = e;
    },
    onReset() {
      this.search = {
        goodsTypeId: "", //运单编号
        goodsType: "",
        id: "",
        tenantId: 0,
        pageIndex: 1,
        pageSize: 10,
        total: 0, //总数
        orgId: this.search.orgId,
        noCount: false,
      };
      console.log(this.search.orgId, "this.search.orgId");
      this.onSearch();
      this.getTree();
    },
    onSearch() {
      this.getList(1);
    },
    onPageChange: function (pageIndex) {
      this.search.pageIndex = pageIndex;
      this.getList(pageIndex);
    },
    onPageSizeChange: function (pageSize) {
      this.search.pageIndex = 1;
      this.search.pageSize = pageSize;
      this.getList();
    },
    getList() {
      this.loading = true;
      if (WLBASE_URL == "https://consul.mes.emergen.cn:6012") {
        this.search.orgId = 2754;
      } else if (WLBASE_URL == "https://consul.mes.emergen.cn:5004") {
        this.search.orgId = 2959;
      } else if (WLBASE_URL == "https://blcapi.nk001.com") {
        this.search.orgId = 2139;
      }
      let url = WLBASE_URL + "/api/PassTokenWaybill/PaginGoodsTypeToSelectDal";
      this.axios({
        url,
        method: "POST",
        data: this.search,
      }).then((res) => {
        if (res.data.success) {
          this.loading = false;
          this.search.total = res.data.content.recordCount;
          this.list = res.data.content.datas;
        } else {
          this.$message.error("请求数据失败");
        }
      });
    },
  },
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.tab {
  padding: 0 20px;
}
.tab_menu .menu_item {
  display: inline-block;
  vertical-align: top;
  width: 80px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  margin-right: 50px;
  cursor: pointer;
  font-size: 14px;
  color: #808080;
}
.tab_menu .menu_item.cur {
  color: #4d4d4d;
  border-bottom: 2px solid #4d4d4d;
}

div >>> .el-table .cell {
  text-align: center;
}
div >>> .el-dialog {
  min-width: 550px;
}
div >>> .el-dialog__body {
  /* background: #f5f5f5; */
  background: #fff;
}
.xqtitle {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  line-height: 24px;
  padding: 0 2px;
  display: inline-block;
  margin-bottom: 20px;
}
.xqtitle::after {
  position: absolute;
  bottom: -4px;
  height: 4px;
  width: 100%;
  background: #5074ee;
  content: "";
  left: 0;
}
#dialog >>> .el-input__inner {
  width: 260px;
  height: 40px;
}
#dialog >>> .el-textarea__inner {
  width: 1235px;
  height: 100px;
}
#dialog >>> .el-table {
  background: #f5f7fa;
}
</style>
